import React, { useCallback, useState } from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import {
  GuideContainer,
  GuideHero,
  BreadCrumbFlex,
  IconImg,
  GuideContentColumn,
  GuideFormColumn,
  HeroWrapper,
} from "./styles/guideDetail.styled"
import MktoForm from "../components/Form/MktoForm"
import { postDetailResolveRichText } from "../utils/resolve-rich-text/blogDetailResolveRichText"
import { BlogSocialShareRow } from "@components/Blog/BlogDetail/BlogSocialShareRow"
import CardDeck from "../components/CardDeck/CardDeck"
import ConversionPanelStandard from "../components/ConversionPanel/ConversionPanelStandard"
import OptimizedImage from "../utils/optimizedImage"

import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"
import PDFEmbedViewer from "../components/Resources/PDFEmbedViewer"
import PDFEmbedThumbnail from "../components/Resources/PDFEmbedThumbnail"

const GuideDetailTemplate = ({ data }) => {
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [showPdf, setShowPdf] = useState(false)
  const [pdfLoaded, setPdfLoaded] = useState(false)

  const guideData = data?.contentfulResource
  const conversionPanel = data?.contentfulComponentConversionPanel
  const cardDeck = data?.contentfulComponentCardDeck
  const cardDeckPosts = guideData?.relatedPosts
  const form = guideData?.form
  const seo = data?.contentfulResource.pageSeo
  const heroImage = data?.contentfulComponentImage
  const externalEmbedUrl = guideData?.pdfFile?.url

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPdfLoaded(true)
  }

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }

  const handleCloseViewer = useCallback(() => {
    setShowPdf(!showPdf)
    setPageNumber(1)
  }, [showPdf])

  const breadStyle = {
    fontWeight: 400,
    textDecoration: "none",
    color: "white",
    transition: "color 200ms ease",
  }

  return (
    <Layout headerTheme={{ theme: "dark", background: "transparent" }}>
      <Seo
        title={seo?.seoTitle || guideData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || heroImage?.primaryImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <GuideHero isCentered={externalEmbedUrl && !form?.marketoFormId}>
        <OptimizedImage
          className="hero-image"
          loading="eager"
          image={heroImage?.primaryImage?.gatsbyImageData}
          src={heroImage?.primaryImage?.file?.url}
          alt="hero-image"
        />
        <BreadCrumbFlex isCentered={externalEmbedUrl && !form?.marketoFormId}>
          <Link href="/">
            <span style={breadStyle}>Home</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p>Resources</p>
            </span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p className="bc-big">Guide</p>
            </span>
          </Link>
        </BreadCrumbFlex>
        {externalEmbedUrl && !form?.marketoFormId && (
          <HeroWrapper>
            {guideData?.category && (
              <p className="eyebrow">{guideData?.category[0]?.title}</p>
            )}
            {guideData?.title && <p className="title">{guideData?.title}</p>}
            {guideData?.description && (
              <p className="description">
                {guideData?.description?.description}
              </p>
            )}
          </HeroWrapper>
        )}
      </GuideHero>
      <GuideContainer withForm={form?.marketoFormId}>
        <GuideContentColumn>
          {!externalEmbedUrl &&
            form?.marketoFormId &&
            guideData?.featuredImage && (
              <OptimizedImage
                image={guideData?.featuredImage?.gatsbyImageData}
                src={guideData?.featuredImage?.file?.url}
                alt={guideData?.title}
                className="featured-img"
              />
            )}
          {form?.marketoFormId && (
            <>
              {guideData?.category && (
                <p className="eyebrow">{guideData?.category[0]?.title}</p>
              )}
              {guideData?.title && <p className="title">{guideData?.title}</p>}
              {guideData?.description && (
                <p className="description">
                  {guideData?.description?.description}
                </p>
              )}
              {guideData?.body && (
                <p className="body">{postDetailResolveRichText(guideData)}</p>
              )}
            </>
          )}

          {externalEmbedUrl && guideData?.featuredImage && (
            <PDFEmbedThumbnail
              pdfLoaded={pdfLoaded}
              featuredImage={guideData?.featuredImage}
              onShowPdfViewer={setShowPdf}
            />
          )}

          {externalEmbedUrl && (
            <PDFEmbedViewer
              show={showPdf}
              externalUrl={externalEmbedUrl}
              numPages={numPages}
              pageNumber={pageNumber}
              onPreviousPage={previousPage}
              onNextPage={nextPage}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              onClosePdfViewer={handleCloseViewer}
            />
          )}

          <BlogSocialShareRow
            className="desktop"
            slug={guideData?.slug}
            iconColor="blue"
            backgroundColor="#F2F4F7"
            downloadUrl={externalEmbedUrl}
            isGated={!!form?.marketoFormId}
          />
        </GuideContentColumn>
        {form?.marketoFormId && (
          <GuideFormColumn>
            <MktoForm
              formId={form?.marketoFormId}
              background={form?.background}
            />
          </GuideFormColumn>
        )}
        <BlogSocialShareRow
          className="mobile"
          slug={guideData?.slug}
          iconColor="blue"
          backgroundColor="#F2F4F7"
          downloadUrl={externalEmbedUrl}
          isGated={!!form?.marketoFormId}
        />
      </GuideContainer>
      {cardDeckPosts ? (
        <CardDeck
          backgroundColor="white"
          heading={cardDeck?.heading}
          subheading={cardDeck?.subheading?.subheading}
          headingSize={cardDeck?.headingSize}
          kicker={cardDeck?.kicker}
          cards={cardDeckPosts}
        />
      ) : (
        <div style={{ paddingTop: "56px" }} />
      )}
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export default GuideDetailTemplate

export const GuideTemplateQuery = graphql`
  query GuideTemplateQuery($id: String) {
    contentfulResource(id: { eq: $id }) {
      id
      title
      slug
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
      }
      publishDate
      externalUrl
      pdfFile {
        url
      }
      category {
        title
        backgroundColor
      }
      featuredImage {
        gatsbyImageData
        file {
          url
        }
      }
      form {
        marketoFormId
        background
      }
      description {
        description
      }
      body {
        raw
      }
      relatedPosts {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            title
            id
          }
          author {
            fullName
            headshot {
              gatsbyImageData
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "0e75d06b-bc8d-5e15-bc19-9af9aa9ebe1f" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
    contentfulComponentCardDeck(
      id: { eq: "062bc928-9946-5756-9982-732d451ad814" }
    ) {
      __typename
      id
      type
      cardType
      kicker
      heading
      headingSize
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
    }
    contentfulComponentImage(
      id: { eq: "5f2c08a5-a616-5ef3-8593-0a166cd9a7b8" }
    ) {
      __typename
      id
      primaryImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
    }
  }
`

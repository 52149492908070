import styled, { css } from "styled-components"
import { color } from "../../atoms/colors"

export const GuideHero = styled.div`
  font-family: Roboto, sans-serif;
  position: relative;
  min-height: 362px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({ isCentered }) =>
    isCentered &&
    `
    padding: 105px 35px 140px;
  `};

  @media (max-width: 992px) {
    min-height: 270px;
    // height: 100%;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
  .hero-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

export const HeroWrapper = styled.div`
  padding: 105px 35px 140px;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;

  max-width: 770px;
  margin: 0 auto;

  .eyebrow {
    color: ${color.electricBlue["200"]};
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .title {
    color: ${color.common.white};
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px;
  }

  .description {
    color: ${color.grey["200"]};
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

export const PDFEmbedThumbnailWrapper = styled.div`
  position: relative;
  img {
    border-radius: 12px;
    overflow: hidden;
  }

  .loading-text {
    position: absolute;
    right: 0;
    top: 0;
    color: ${color.common.white};
    font-size: 14px;
    padding: 8px 4px;
    pointer-events: none;
  }

  .show-button {
    position: absolute;
    border: none;
    background: transparent;
    right: 0;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
`

export const BreadCrumbFlex = styled.div`
  margin-top: 100px;
  position: absolute;
  z-index: 100;
  padding: 24px 15px;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1170px;
  transform: translate(-50%, 0%);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  ${({ isCentered }) =>
    isCentered &&
    `
    margin-top: 0;
  `};

  a {
    text-decoration: none;
  }
  img {
    padding: 0 10px;
    transform: scale(80%) translateY(2px);
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease;
    &:hover {
      color: #dddddd;
    }
  }
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const IconImg = styled.div`
  img {
    &:last-child {
      display: none;
    }
  }
`

const pdfViewerStyles = css`
  .pdf-viewer {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    color: ${color.common.white};
    position: relative;

    .react-pdf__Page {
      &.active {
        display: block;
      }
      &.in-active {
        display: none;
      }
    }

    canvas.react-pdf__Page__canvas,
    .react-pdf__Page__textContent.textLayer,
    .react-pdf__Page__annotations.annotationLayer {
      width: 100% !important;
      height: 100% !important;
    }

    .react-pdf__Page__textContent.textLayer,
    .react-pdf__Page__annotations.annotationLayer {
      pointer-events: none;
    }
  }
`

export const PDFWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 100px;

  @media (min-width: 992px) {
    width: 992px;
    margin-top: 0;
  }
`

export const PDFViewerMain = styled.div`
  display: ${props => (props?.show ? "block" : "none")};
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 24px;

  @media (min-width: 992px) {
    padding: 128px 96px 96px;
  }

  ${pdfViewerStyles};

  .page-details {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
    font-size: 14px;
    color: ${color.common.white};
    width: 100%;
  }

  .button-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    color: ${color.common.white};

    button {
      background: transparent;
      border-radius: 4px;
      padding: 4px 8px;
      outline: none;
      color: ${color.common.white};
      border: none;

      font-size: 14px;

      &:enabled:hover {
        background-color: ${color.grey["300"]};
        cursor: pointer;
        color: ${color.common.black};
      }
    }
  }

  .close-button {
    border: none;
    background: transparent;
    cursor: pointer;
    align-self: end;
    margin-bottom: 24px;
  }
`

export const GuideContentColumn = styled.div`
  position: relative;
  margin: 0 auto;
  .featured-img {
    margin-bottom: 32px;
    aspect-ratio: 150 / 83;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .eyebrow {
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #475467;
    margin-bottom: 16px;
  }
  .title {
    font-weight: 900;
    font-size: 40px;
    line-height: 60px;
    color: #252d38;
    margin-bottom: 16px;
  }
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #475467;
  }
  .body {
    margin-top: 32px;
  }
  .desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  ${pdfViewerStyles};
`

export const GuideContainer = styled.div`
  position: relative;
  margin: -180px auto 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  display: grid;
  grid-template-columns: ${props => (props?.withForm ? "auto auto" : "auto")};
  column-gap: 30px;
  font-family: Roboto, sans-serif;
  @media (max-width: 992px) {
    max-width: 770px;
    margin-top: -90px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
    margin-top: -120px;
    grid-template-columns: auto;
  }
  .empty {
    margin-top: 230px;
    @media (max-width: 992px) {
      margin-top: 140px;
    }
    @media (max-width: 768px) {
      margin-top: 170px;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
      width: 100%;
    }
  }

  ${GuideContentColumn} {
    max-width: ${props => (props?.withForm ? "570px" : "770px")};
  }
`

export const GuideFormColumn = styled.div`
  max-width: 570px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`
